// Vendors
import Vue from 'vue'
import 'bootstrap';
import 'jquery'

// Utilities
import router from './router'
import store from './store'
import '@/Features'

// Base App
import App from './AppContainer.vue'
import './assets/sass/app.scss'

import * as toastr from 'toastr';
import '@/utilities/flatMapPolyfill'
import {loadExtension} from "@/services/BrowserExtension";

// Load these last, order is important
import '@/init/VueInit';
import {resetAllFeatureFlags} from '@/init/InitWindowCli';
import {appApi} from '@/init/ApiInit';
import '@/init/InitWindowCli';
import {isChromeExtension} from "@/services/Context";


if (!isChromeExtension()) {
    import('@/init/VueInitWebOnly') // must be imported dynamically (https://medium.com/dailyjs/webpack-4-splitchunks-plugin-d9fbbe091fd0)
        .then(() => {
            // Global Event Listener
            window['Events'] = new Vue({});
        })
        .then(() =>
            // Instantiate the App
            new Vue({
                el : "#app",
                render: h => h(App),
                store,
                router
            })
        )
}

resetAllFeatureFlags()

export { router, store, appApi };

toastr.options.closeButton = true;
toastr.options.closeDuration = 5;
